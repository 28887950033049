import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { DataUtil } from '@celum/core';
import { CelumPipesModule } from '@celum/ng2base';
import { createEmptyUserGroupFilter, UserGroupFilters } from '@celum/sacc/domain';
import { CelumChipComponent, CelumChipSetComponent } from '@celum/shared/ui';

interface Chip {
  title?: string;
  value: string;
  onRemove: () => void;
}

@Component({
  selector: 'sacc-user-group-filter-chips',
  templateUrl: './user-group-filter-chips.component.html',
  styleUrl: 'user-group-filter-chips.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, CelumChipSetComponent, CelumChipComponent, CelumPipesModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class UserGroupFilterChipsComponent implements OnChanges {
  @HostBinding('class.celum-filter-chips') protected readonly hostClass = true;

  @Input() public filter: UserGroupFilters;

  @Output() public readonly filterChanged = new EventEmitter<UserGroupFilters>();

  protected chips = signal<Chip[]>([]);

  public ngOnChanges({ filter }: SimpleChanges): void {
    this.chips.set(this.filterToChips(filter.currentValue));
  }

  protected trackFn(index: number): number {
    return index;
  }

  protected filterToChips(filters: UserGroupFilters): Chip[] {
    const chips = [];

    if (!DataUtil.isEmpty(filters.name)) {
      chips.push({
        value: `"${filters.name}"`,
        title: '',
        onRemove: () =>
          this.filterChanged.emit({
            ...filters,
            name: ''
          })
      });
    }

    if (filters.imported) {
      chips.push({
        title: '',
        value: 'COMPONENTS.USER_GROUPS.FILTER.SEARCH.TYPES.IMPORTED.CHIP',
        onRemove: () =>
          this.filterChanged.emit({
            ...filters,
            imported: false
          })
      });
    }

    return chips;
  }

  protected clearAllFilters(): void {
    this.filterChanged.emit(createEmptyUserGroupFilter(this.filter.sort));
  }
}
