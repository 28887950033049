import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { debounceTime, switchMap, tap } from 'rxjs';

import { AccountAccessStatus, InvitationStatus } from '@celum/authentication';
import { tapAllResponses } from '@celum/core';
import { Account, AccountMember, createEmptyAccountMemberFilter, UserGroup } from '@celum/sacc/domain';
import { AccountMemberResourceService } from '@celum/sacc/shared';
import { FilterState } from '@celum/shared/domain';
import { ErrorService } from '@celum/shared/util';

export type UserFilterState = {
  userLimit?: number;
  addedUsers?: AccountMember[];
  removedUsers?: AccountMember[];
  userGroup?: UserGroup;
  account?: Account;
} & FilterState<AccountMember>;

export type UserFilterViewModel = {
  selectedValues?: AccountMember[];
  loading: boolean;
  isOpen?: boolean;
  data?: AccountMember[];
  searchValue?: string;
} & UserFilterState;

@Injectable()
export class UserFilterService extends ComponentStore<UserFilterState> {
  public static readonly visibleUserStatuses = [InvitationStatus.APPROVED, InvitationStatus.INVITED, InvitationStatus.ACCEPTED];

  public vm$ = this.select(state => this.createViewModel(state));

  public loadUsersForAccountAccess = this.effect<void>(load$ =>
    load$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(() =>
        this.accountMemberResourceService.search(
          this.get().account.id,
          { ...createEmptyAccountMemberFilter(), nameOrEmail: this.get().searchValue },
          UserFilterService.visibleUserStatuses,
          [AccountAccessStatus.ACTIVE],
          { count: 250 }
        )
      ),
      tapAllResponses(
        result => {
          this.patchState({
            filteredValues: result.entities,
            loading: false
          });
        },
        error => {
          this.errorService.httpError(error as HttpErrorResponse, 'GroupFilterService');
          this.patchState({ filteredValues: [], loading: false });
        }
      )
    )
  );

  constructor(
    private errorService: ErrorService,
    private accountMemberResourceService: AccountMemberResourceService
  ) {
    super({
      selectedValues: [],
      searchValue: '',
      loading: false,
      filteredValues: null,
      isOpen: false,
      onTouched: null,
      onChange: null
    });

    this.select(state => state.searchValue)
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.loadUsersForAccountAccess();
      });
  }

  public selectionChanged(selectedValues: AccountMember[]): void {
    this.patchState({ selectedValues });
  }

  public toggleMenu(value: boolean): void {
    this.patchState({ isOpen: value });
    if (value) {
      this.loadUsersForAccountAccess();
      return;
    }
    this.patchState({ searchValue: '' });
  }

  public searchValueChanged(searchValue: string): void {
    this.patchState({ searchValue });
  }

  private getSelectedValues(filteredValues: any[], removedUsers: AccountMember[], addedUsers: AccountMember[], userGroup?: UserGroup): AccountMember[] {
    const usersInGroup = userGroup ? filteredValues?.filter(user => user.groups.some((group: UserGroup) => group.id === userGroup?.id)) : [];
    const usersWithoutRemoved = usersInGroup?.filter(user => !removedUsers.some(removedUser => removedUser.email === user.email));

    return usersWithoutRemoved ? [...usersWithoutRemoved, ...addedUsers] : [...addedUsers];
  }

  private createViewModel(state: UserFilterState): UserFilterViewModel {
    return {
      ...state,
      selectedValues: this.getSelectedValues(state.filteredValues, state.removedUsers, state.addedUsers, state.userGroup),
      loading: state.loading,
      isOpen: state.isOpen,
      data: state.filteredValues,
      searchValue: state.searchValue
    };
  }
}
