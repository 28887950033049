@if (service.vm$ | async; as vm) {
  <celum-icon
    class="user-filter_add-user"
    cdkOverlayOrigin
    data-test-add-user-icon
    #originOverlay="cdkOverlayOrigin"
    [configuration]="addNewUser"
    [matTooltip]="'COMPONENTS.USER_GROUPS.CREATE_DIALOG.ADD_GROUP_MEMBERS' | translate"
    (click)="service.toggleMenu(true)"></celum-icon>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="vm.isOpen"
    [cdkConnectedOverlayOrigin]="originOverlay"
    [cdkConnectedOverlayPanelClass]="['mat-elevation-z3', 'user-filter_search-and-select']"
    (overlayOutsideClick)="service.toggleMenu(false)">
    <celum-search-and-select
      data-test-search-and-select
      [bottomItem]="bottomItemTemplate"
      [compareFn]="compareFn"
      [disabledCheckboxTooltip]="'COMPONENTS.USER_GROUPS.CREATE_DIALOG.MAX_NUMBER_REACHED' | translate: { userLimit: vm.userLimit }"
      [hasMoreBottom]="false"
      [itemTemplate]="itemTemplate"
      [items]="vm.data"
      [loadingItems]="vm.loading"
      [maxSelectableItems]="vm.userLimit"
      [multipleSelection]="true"
      [noItemFoundIcon]="null"
      [noItemText]="'COMPONENTS.USER_GROUPS.CREATE_DIALOG.NO_MATCHING_PEOPLE_FOUND' | translate: { searchValue: vm.searchValue }"
      [searchBoxPlaceholderText]="'COMPONENTS.USER_GROUPS.CREATE_DIALOG.SEARCH_FOR_MEMBERS'"
      [selectedItems]="vm.selectedValues"
      (itemSelectionChanged)="selectionChanged($event, vm.selectedValues)"
      (searchValueChanged)="service.searchValueChanged($event)"></celum-search-and-select>
  </ng-template>

  <ng-template #itemTemplate let-item>
    <div class="user-filter_user-item" data-test-user-item>
      <celum-user-avatar class="user-filter_user-item_avatar" [accountAccessToken]="vm.account?.accountAccessToken" [user]="item"></celum-user-avatar>
      <div class="user-filter_user-item_data">
        @if (item.firstName || item.lastName) {
          <div class="user-filter_user-item_name" spaceAwareTooltip [matTooltip]="item.firstName + '&nbsp;' + item.lastName">
            {{ item.firstName }}&nbsp;{{ item.lastName }}
          </div>
        }
        <div class="user-filter_user-item_email" [matTooltip]="item.email" spaceAwareTooltip>
          {{ item.email }}
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #bottomItemTemplate>
    @if (vm.data.length >= 250) {
      <div class="user-filter_search-and-select_specify-search" data-test-specify-search>
        {{ 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.SPECIFY_SEARCH' | translate }}
      </div>
    }
  </ng-template>
}
