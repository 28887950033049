import { CelumPropertiesProvider } from '@celum/core';

export interface CelumSaccProperties {
  apiUrl?: string;
  featureFlags?: {
    organisationDeletion?: boolean;
    invitationEditDialog?: boolean;
    contentTemplateLicenseFlag?: boolean;
  };
  userGroupMemberLimit?: number;
  userGroupCountLimit?: number;
  pictureUpload: {
    maxSize: number;
    maxWidthOrHeight: number;
  };
}

export class SaccProperties {
  static get properties(): CelumSaccProperties {
    const testingFallback = {};
    return CelumPropertiesProvider.properties.appProperties?.sacc ?? testingFallback;
  }
}
