import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { DataUtil } from '@celum/core';
import { CelumPipesModule } from '@celum/ng2base';
import { AccountMemberFilters, createEmptyAccountMemberFilter } from '@celum/sacc/domain';
import { CelumChipComponent, CelumChipSetComponent } from '@celum/shared/ui';

import { accountMemberFilterItemByValue } from '../account-member-filter/account-member-filter.item';

interface Chip {
  title?: string;
  value: string;
  onRemove: () => void;
}

@Component({
  selector: 'sacc-account-member-filter-chips',
  templateUrl: './account-member-filter-chips.component.html',
  styleUrl: 'account-member-filter-chips.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, CelumChipSetComponent, CelumChipComponent, CelumPipesModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AccountMemberFilterChipsComponent implements OnChanges {
  @HostBinding('class.celum-filter-chips') protected readonly hostClass = true;

  @Input() public filter: AccountMemberFilters;

  @Output() public readonly filterChanged = new EventEmitter<AccountMemberFilters>();

  protected chips = signal<Chip[]>([]);

  constructor(private translateService: TranslateService) {}

  public ngOnChanges({ filter }: SimpleChanges): void {
    this.chips.set(this.filterToChips(filter.currentValue));
  }

  protected trackFn(index: number): number {
    return index;
  }

  protected filterToChips(filters: AccountMemberFilters): Chip[] {
    const chips = [];

    if (!DataUtil.isEmpty(filters.nameOrEmail)) {
      chips.push({
        value: `"${filters.nameOrEmail}"`,
        title: '',
        onRemove: () =>
          this.filterChanged.emit({
            ...filters,
            nameOrEmail: ''
          })
      });
    }

    if (!DataUtil.isEmpty(filters.status)) {
      filters.status.forEach(value => {
        const filterItem = accountMemberFilterItemByValue[value];

        let displayValue = this.translateService.instant(`COMPONENTS.ACTIVATION_STATUS.${filterItem.value.toUpperCase()}`);
        displayValue = `${displayValue.charAt(0).toUpperCase()}${displayValue.slice(1)}`;

        chips.push({
          title: filterItem.typeTitle,
          value: displayValue,
          onRemove: () => this.filterChanged.emit({ ...filters, status: filters.status.filter(status => status !== filterItem.value) })
        });
      });
    }

    if (!DataUtil.isEmpty(filters.groups)) {
      filters.groups.forEach(group => {
        chips.push({
          title: 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.GROUPS.TITLE',
          value: group.name,
          onRemove: () => this.filterChanged.emit({ ...filters, groups: filters.groups.filter(existingGroup => existingGroup.id !== group.id) })
        });
      });
    }

    if (!DataUtil.isEmpty(filters.roles)) {
      filters.roles.forEach(value => {
        const filterItem = accountMemberFilterItemByValue[value];
        chips.push({
          title: filterItem.typeTitle,
          value: `COMPONENTS.ACCOUNT_MEMBER_TABLE.MEMBER_ROLE.${filterItem.value.toUpperCase()}`,
          onRemove: () => this.filterChanged.emit({ ...filters, roles: filters.roles.filter(role => role !== filterItem.value) })
        });
      });
    }

    if (filters.imported) {
      chips.push({
        title: '',
        value: 'COMPONENTS.ACCOUNT_MEMBER_TABLE.FILTER.SEARCH.TYPES.IMPORTED.CHIP',
        onRemove: () =>
          this.filterChanged.emit({
            ...filters,
            imported: false
          })
      });
    }

    return chips;
  }

  protected clearAllFilters(): void {
    this.filterChanged.emit(createEmptyAccountMemberFilter(this.filter.sort));
  }
}
